.thankyou-container {
    width: 100%;
    height: 100vh;
    background-color: #ebeae8;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #666;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.thankyou-container .header {
    font-size: 1.6rem;
    line-height: 1.75rem;
    font-weight: bold;
}

.thankyou-container .sub-header {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: normal;
    padding: 15px;
    margin: 5px 0 35px 0;
}

#continue-button {
    padding: 8px 20px;
    font-size: 1rem;
    color: #333333;
    border-radius: 8px;
    border: 0;
    min-width: 180px;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px 0px #00000033;
}

#continue-button:focus,
#continue-button:hover {
    background-color: #ebeae8;
}

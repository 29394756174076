.selectableImage {
    border: 2px solid white;
    max-width: 22vh;
    height: auto;
    margin: 1px;
    transition-duration: 100ms;
}

.selectableImage.selected {
    border-color: #000;
}

.selectableImage:hover {
    border-color: #f77197;
    cursor: pointer;
}

.four_by_four_grid {
    display: grid;
    justify-content: center;
    grid-template-columns:
        max-content
        max-content
        max-content
        max-content;
    grid-template-rows:
        max-content
        max-content
        max-content
        max-content;
}

.three_by_three_grid {
    display: grid;
    justify-content: center;
    grid-template-columns:
        max-content
        max-content
        max-content;
    grid-template-rows:
        max-content
        max-content
        max-content;
}

.two_by_two_grid {
    display: grid;
    justify-content: center;
    grid-template-columns:
        max-content
        max-content;
    grid-template-rows:
        max-content
        max-content;
}

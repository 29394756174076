.button.dag_button {
    color: #666;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    background-color: #ffffff;
    margin-top: 2px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button.dag_button:focus {
    background-color: #e4e4e4;
}

.button.dag_button:hover {
    background-color: #efefef;
}

.dag_button#undo {
    font-size: 1.4rem;
    color: #666;
    width: 30px;
    height: 30px;
}

.dag_button#undo:focus {
    background-color: #e4e4e4;
}

.dag_button#undo:hover {
    background-color: #efefef;
}

.dag_button#feedback {
    font-size: 1.4rem;
    color: #666;
    width: 30px;
    height: 30px;
}

.dag_button#feedback:focus {
    background-color: #e4e4e4;
}
.dag_button#feedback:hover {
    background-color: #efefef;
}

.dag_button#video-instructions {
    font-size: 2rem;
    color: #666;
}

.dag_button#video-instructions:focus,
.dag_button#video-instructions:hover {
    background-color: #ebeae8;
}

.dag_button#info {
    font-size: 1.4rem;
    color: #666;
    width: 30px;
    height: 30px;
}

.dag_button#info:focus {
    background-color: #e4e4e4;
}

.dag_button#info:hover {
    background-color: #efefef;
}

.dag_button#cant-solve {
    color: #666;
    background-color: #ffffff;
    font-weight: 500;
    font-size: 1.5rem;
    width: 50px;
    height: 50px;
}

.dag_button#cant-solve:focus {
    background-color: #e4e4e4;
}

.dag_button#cant-solve:hover {
    background-color: #efefef;
}

.dag_button#yes {
    background-color: #00802b;
    color: #fff;
    font-weight: 500;
    font-size: 1.1rem;
    width: 50px;
    height: 50px;
}

.dag_button#yes:focus {
    animation: pulse-green 0.5s;
}

.dag_button#yes:hover {
    background-color: #006422;
    color: #fff;
}

.dag_button#no {
    background-color: #cc0000;
    color: #fff;
    font-weight: 500;
    font-size: 1.1rem;
    width: 50px;
    height: 50px;
}

.dag_button#no:hover {
    background-color: #b40202;
}

.dag_button#no:focus {
    animation: pulse-red 0.5s;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(204, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(204, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(204, 0, 0, 0);
    }
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 128, 43, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 128, 43, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 128, 43, 0);
    }
}

/*
intended image resizing behaviour:
    - fixed height image container
    - images keep their aspect ratio
    - smaller images are upscaled with nearest-neighbour to fit the container
    - larger images are downscaled to fit the container
*/
#dag_image_container {
    height: 55vh;
    width: 55.5vh;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 25px 0 10px 0;
    box-sizing: border-box;
    background-color: #eee;
    border-radius: 5px;
}

@media only screen and (min-width: 320px) {
    #dag_image_container {
        /* for small mobile */
        height: 30vh;
        width: 30.3vh;
    }
}

@media only screen and (min-width: 375px) {
    #dag_image_container {
        /* for medium mobile */
        height: 33vh;
        width: 33.33vh;
    }
}

@media only screen and (min-width: 410px) {
    #dag_image_container {
        /* for large mobile */
        height: 36vh;
        width: 36.36vh;
    }
}

@media only screen and (min-width: 768px) {
    #dag_image_container {
        /* for tablet */
        height: 36vh;
        width: 36.36vh;
    }
}

@media only screen and (min-width: 1024px) {
    #dag_image_container {
        /* for laptop */
        height: 45vh;
        width: 45.45vh;
    }
}

@media only screen and (min-width: 1440px) {
    #dag_image_container {
        /* for large laptop */
        height: 50vh;
        width: 50.5vh;
    }
}

@media only screen and (min-width: 2560px) {
    #dag_image_container {
        /* for desktop */
        height: 60vh;
        width: 60.6vh;
    }
}

#dag_image_container > #dag_image {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    image-rendering: pixelated;
    border-radius: 5px;
}

#dag_heading {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 50px 0 0px 0;
    color: #666;
    font-size: 1.5rem;
    background-color: #eee;
}

@media only screen and (min-width: 320px) {
    #dag_heading {
        /* for small mobile */
        font-size: 0.8rem;
    }
}

@media only screen and (min-width: 375px) {
    #dag_heading {
        /* for medium mobile */
        font-size: 0.9rem;
    }
}

@media only screen and (min-width: 410px) {
    #dag_heading {
        /* for large mobile */
        font-size: 1.1rem;
    }
}

@media only screen and (min-width: 768px) {
    #dag_heading {
        /* for tablet */
        font-size: 1.32rem;
    }
}

@media only screen and (min-width: 1024px) {
    #dag_heading {
        /* for laptop */
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1440px) {
    #dag_heading {
        /* for large laptop */
        font-size: 1.58rem;
    }
}

@media only screen and (min-width: 2560px) {
    #dag_heading {
        /* for desktop */
        font-size: 1.65rem;
    }
}

#action-button-box {
    width: 210px;
    height: 65px;
    background: #e3e3e3;
    border-radius: 56px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 300px;
    padding-top: 5px;
    padding-bottom: 10px;
}

@media only screen and (min-width: 768px) {
    #action-button-box {
        /* for tablet */
        max-width: 400px;
    }
}

@media only screen and (min-width: 1024px) {
    #action-button-box {
        /* for laptop */
        max-width: 500px;
    }
}

.meta-action-button-box {
    padding: 5px 0 10px 0;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 55%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.feedback-dialog {
    border-radius: 50px;
}

.progressbar_qm {
    margin-bottom: 1em;
}

.progressbar_qm .progress-bar {
    background-color: #10294b;
    position: relative;
}

.progressbar_qm .progress {
    overflow: unset;
    height: 0.375rem;
    background-color: #c2c8d1;
    box-shadow: 0 0.5em 1em #c2c8d1;
    border-radius: 0;
}
.progressbar_qm .progress_bar_label {
    color: #757575;
    font-size: 0.8rem;
}

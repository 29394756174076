.polygonsCanvas {
    display: flex;
    flex-direction: ltr;
    justify-content: space-evenly;
}

.polygonsCanvas > div {
    min-width: 150px;
}

/* zoomable */
.polygonsCanvas .zoomableContainer {
    border: 10px solid #000;
    background-color: #000;
    overflow: hidden;
}

.polygonsCanvas .zoomableContainer > div > div {
    position: relative;
    transition: transform 100ms linear, transform-origin 0.14 ease-out;
    overflow: hidden;
    object-fit: none;
}

/* Left menu */
.btn-group-vertical {
    width: 250px;
}

.polygonsCanvas .dropdown {
    margin-bottom: 1em;
}

.polygonsCanvas .dropdown .dropdown-toggle:after {
    margin-left: 1em;
}

.polygonsCanvas .dropdown,
.polygonsCanvas .dropdown .dropdown-toggle,
.polygonsCanvas .labelsDropdown > button:first-child {
    text-transform: capitalize;
    width: 100%;
}

.polygonsCanvas .reference_image {
    margin-top: auto;
    margin-bottom: auto;
    width: 250px;
    height: 334px;
    margin-left: -2px;
}
.polygonsCanvas .reference_image img {
    width: 100%;
}
.polygonsCanvas .referenceZoom {
    position: relative;
    transition-duration: 250ms;
    transform-origin: right;
    z-index: 1;
    border: black 2px solid;
}
#zoom_icon {
    position: relative;
    bottom: 32px;
    left: 218px;
    width: 30px;
    background: white;
    opacity: 0.7;
    z-index: 2;
}

.qm_svg {
    pointer-events: all;
}

/* Interaction area */
.interactionArea {
    cursor: crosshair;
    fill: transparent;
    pointer-events: all;
    user-select: none;
}
.dragging .interactionArea {
    cursor: grab;
}
.activePolygon:not(.drawing) .interactionArea {
    cursor: default;
}

/* Lines around the polygon */
.qm_line {
    cursor: crosshair;
    stroke-width: 1;
}
.active .qm_line {
    pointer-events: all;
}
.dragging .active .qm_line,
.drawing .active .qm_line {
    pointer-events: none;
}
.active.closed .qm_line {
    stroke-width: 3;
}
.qm_line.intersectioned {
    stroke: red !important;
}

/* The polygons */
.qm_polygon {
    cursor: pointer;
    fill-opacity: 0.3;
    pointer-events: all;
    stroke-width: 0;
}
.drawing .qm_polygon,
.dragging .qm_polygon {
    pointer-events: none;
    fill: transparent !important;
}
.closed.inactive .qm_polygon:not(:hover) {
    fill: transparent !important;
}
.active.closed .qm_polygon {
    pointer-events: none;
}

/* Edges of the polygons */
.qm_circle {
    cursor: pointer;
    display: none;
    pointer-events: none;
}
.active .qm_circle {
    display: inline;
    pointer-events: all;
}
.dragging .active .qm_circle {
    pointer-events: none;
}
.active .qm_circle:hover {
    cursor: grab;
}
.active.unclosed .qm_circle.start:hover {
    cursor: pointer;
}
.unclosed .qm_circle.start,
.active .qm_circle.selected,
.active .qm_circle:hover {
    fill: white !important;
}
.active.unclosed .qm_circle.selected {
    animation: pulseFill 1.2s ease-in-out infinite;
}

@keyframes pulseFill {
    0%,
    100% {
        fill-opacity: 1;
    }
    50% {
        fill-opacity: 0.5;
    }
}

.continuousDrawing .qm_line,
.continuousDrawing .qm_polygon,
.continuousDrawing .qm_circle:not(.start) {
    pointer-events: none !important;
}

.userblocked-container {
    width: 100%;
    height: 100vh;
    background-color: #ebeae8;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #666;
    font-size: 1.5rem;
    font-family: "Oswald";
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.userblocked-container .header {
    font-size: 1.6rem;
    line-height: 1.75rem;
    font-weight: bold;
}

.userblocked-container .sub-header {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: normal;
    padding: 15px;
    margin: 5px 0 35px 0;
}

.instructionsCarouselContainer {
    text-align: center;
}

.new-container-qm {
    width: fit-content;
    height: fit-content;
    text-align: center;
    background-color: #eee;
    border-radius: 5px;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    border: 1px solid rgb(223, 223, 223);
    font-size: 1.2rem;
    max-width: 90%;
}

@media only screen and (min-width: 768px) {
    .new-container-qm {
        /* for desktop */
        max-width: 50%;
        font-size: 1.6rem;
    }
}

.carousel.slide {
    min-height: 300px;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.carousel.slide,
.carousel-inner,
.carousel-item,
.instructionsCarouselSlide {
    text-align: center;
}

.instructionsCarouselSlide img {
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.1);
}
.instructionsCarouselSlide p {
    max-width: 334px;
    margin: 2.5rem auto 1.5rem;
    min-height: 4rem;
}

ol.carousel-indicators {
    bottom: 5.25rem;
}
ol.carousel-indicators li {
    background-color: gray;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
}
ol.carousel-indicators li.active {
    background-color: #666;
}

.carousel-control-prev,
.carousel-control-next {
    background-color: #666;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    top: unset;
    bottom: 6.75rem;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.24);
    opacity: 1;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.carousel-control-prev:focus,
.carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
    opacity: 1;
    background-color: #666;
}
.carousel-control-prev {
    transform: translateX(-0.5rem);
}
@media all and (min-width: 480px) {
    .carousel-control-prev {
        transform: translateX(-1.25rem);
    }
}
.carousel-control-prev-icon {
    background-size: 1rem;
    /* TODO:
          InstructionsGaming.css and Instructions.css are both loaded and override this background-image property...
          We can't change the selector because that's a 3rd party element
          and we have no way to scope the css imports only to Instructions or InstructionsGaming.
          --> Scoping CSS is an open wound in this codebase...
    */
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next {
    transform: translateX(0.5rem);
}
@media all and (min-width: 480px) {
    .carousel-control-next {
        transform: translateX(1.25rem);
    }
}
.carousel-control-next-icon {
    background-size: 1rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.instructionsCarouselContainer .closeInstructionsButton {
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0s forwards
        init-instruction-close-button;
}
@keyframes init-instruction-close-button {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

#understood-button.button {
    font-size: 1.2rem;
    color: #666;
    background-color: #eeeeee;
    box-shadow: -2px -2px 4px rgba(255, 255, 255, 1),
        1px 1px 4px rgba(0, 0, 0, 0.25);
}

.rc-slider {
    margin-top: 0.8rem;
}

/* the canvas_scene for the orientation adjustment UI can be larger than for the keypoint UI */
.orientation_adjustment_canvas_scene {
    max-height: 33vh !important;
    margin: auto;
}

.progressbar_qm {
    margin-bottom: -0.4rem;
}

.rotation_slider_description {
    margin-bottom: -0.4rem;
}

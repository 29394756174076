.continue-button {
    margin-top: 10%;
    background-color: #10294b;
    border: 2px solid #343a40;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
.continue-button:hover {
    border: 2px solid #f77196;
    color: white;
}

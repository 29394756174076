.rc-slider {
    margin-top: 0.8rem;
}

/* the canvas_scene for the bbox UI can be larger than for the keypoint UI */
.bbox_canvas_scene {
    max-height: 44vh !important;
}

.progressbar_qm {
    margin-bottom: -0.4rem;
}

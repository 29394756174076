.layered_canvas_scene {
    /* margin-left: 5px;
    margin-right: 5px; */
}

.canvas_scene {
    width: auto !important;
    max-width: 100%;
    max-height: 81vh !important;
}

.mock_data_form {
    flex-grow: 1;
}

.mock_data_form:last-child {
    margin-left: 20px;
}

/* proper visual boundary for single array elements  */
.mock_data_form
    .form-group
    .container-fluid
    .d-flex.row
    > div:first-child
    > .form-group {
    border: 1px solid rgb(165, 161, 161);
    padding: 5px;
}

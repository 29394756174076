.progressbar_container {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.progress_dot_on {
    width: 10px;
    height: 10px;
    margin: 0 4px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    box-sizing: border-box;
    background-color: #666;
    box-shadow: inset 0px -2px 3px rgba(0, 0, 0, 0.75),
        inset 0px 2px -2px rgba(255, 255, 255, 0.5);
}

.progress_dot_off {
    width: 10px;
    height: 10px;
    margin: 0 4px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    box-sizing: border-box;
    background-color: #e9e9e9;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25),
        inset 0px -4px 4px #ffffff;
}

.progress_stroke_background {
    width: 100%;
    height: 10px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background-color: #e9e9e9;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25),
        inset 0px -4px 4px #ffffff;
}

.popup_text {
    padding: 20px;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}
.popup_inner {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    top: 30%;
    margin: auto;
    background: white;
    border: 5pt grey solid;
    width: fit-content;
    height: auto;
    display: flex;
}

.popup_inner #loading_animation {
    padding: 20px;
    margin-top: 10px;
}

/* prevents the gradient image from overlaying the marker image --> so it stays clickable */
.rc_slider_horizontal .rc-slider-mark .rc-slider-mark-text {
    z-index: 999;
}

.rc-slider-mark-text {
    pointer-events: none;
}



.spotify_audioplayer_container {
    display: block;
    background-color: #000;
    color: #fff;
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.spotify_audioplayer_progress {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: sans-serif;
    font-size: 30px;
    padding-top: 15px;
}

.spotify_audioplayer_progress_current {
    font-weight: lighter;
}

.spotify_audioplayer_progress_duration {
    font-weight: 400;
}

.spotify_audioplayer_play_button {
    all: unset;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    height: 150px;
    padding: 20px;
    cursor: pointer;
}

.spotify_audioplayer_spotify_link {
    display: block;
    line-height: 36px;
    text-align: right;
    padding: 20px;
}

.spotify_audioplayer_spotify_listen_on {
    color: white;
    line-height: 36px;
    height: 36px;
    display: inline-block;
    margin-left: auto;
    margin-right: 5px;
}

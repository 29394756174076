.rc-slider {
    margin-top: 0.8rem;
}

/* the canvas_scene for the orientation adjustment UI can be larger than for the keypoint UI */
.bbox_face_adjustment_canvas_scene {
    max-height: 32vh !important;
    margin: auto;
}

.progressbar_qm {
    margin-bottom: -0.4rem;
}

.bbox_face_adjustment_slider_heading {
    margin-bottom: 0;
}

.no_margin {
    margin: 0 !important;
}

.button_col {
    display: flex;
    align-items: center;
    justify-content: center;
}
